import axios from '@/libs/axios'
// eslint-disable-next-line import/no-cycle
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
    dataSourcesQuestions: [],
    totalRowsQuestions: 0,
    dataSourcesExchangeQuestions: [],
    totalRowsExchangeQuestions: 0,
    listCourseSemesterSubject: [],
    allTrainingSystems: [],
    coursesSemesters: [],
    dataSourcesByStatus: [],
    totalRowsByStatus: 0,
    dataSourcesSelected: [],
    dataSourcesProclaimed: [],
    totalRowsSelected: 0,
    totalRowsProclaimed: 0,
  },
  getters: {
    dataSources: state => state.dataSources,
    dataSourcesByStatus: state => state.dataSourcesByStatus,
    dataSourcesProclaimed: state => state.dataSourcesProclaimed,
    dataSourcesSelected: state => state.dataSourcesSelected,
    totalRows: state => state.totalRows,
    totalRowsByStatus: state => state.totalRowsByStatus,
    totalRowsSelected: state => state.totalRowsSelected,
    totalRowsProclaimed: state => state.totalRowsProclaimed,
    dataSourcesQuestions: state => state.dataSourcesQuestions,
    totalRowsQuestions: state => state.totalRowsQuestions,
    dataSourcesExchangeQuestions: state => state.dataSourcesExchangeQuestions,
    totalRowsExchangeQuestions: state => state.totalRowsExchangeQuestions,
    listCourseSemesterSubject: state => state.listCourseSemesterSubject,
    allTrainingSystems: state => state.allTrainingSystems,
    coursesSemesters: state => state.coursesSemesters,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_DATA_SOURCES_BY_STATUS: (state, data) => { state.dataSourcesByStatus = data },
    SET_DATA_SOURCES_SELECTED: (state, data) => { state.dataSourcesSelected = data },
    SET_DATA_SOURCES_PROCLAIMED: (state, data) => { state.dataSourcesProclaimed = data },
    SET_DATA_SOURCES_QUESTIONS: (state, data) => { state.dataSourcesQuestions = data },
    SET_DATA_SOURCES_EXCHANGE_QUESTIONS: (state, data) => { state.dataSourcesExchangeQuestions = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_TOTAL_ROWS_BY_STATUS: (state, data) => { state.totalRowsByStatus = data },
    SET_TOTAL_ROWS_SELECTED: (state, data) => { state.totalRowsSelected = data },
    SET_TOTAL_ROWS_PROCLAIMED: (state, data) => { state.totalRowsProclaimed = data },
    SET_TOTAL_ROWS_QUESTION: (state, data) => { state.totalRowsQuestions = data },
    SET_TOTAL_ROWS_EXCHANGE_QUESTION: (state, data) => { state.totalRowsExchangeQuestions = data },
    SET_TRAINING_SYSTEMS: (state, data ) => {
      state.allTrainingSystems = data
    },
    SET_COURSE_SEMESTER_SUBJECT: (state, { data }) => {
      state.listCourseSemesterSubject = data
    },
    SET_COURSES_SEMESTERS: (state, { data }) => {
      state.coursesSemesters = data
    },
  },
  actions: {
    async createManually({ commit }, params) {
      try {
        const response = await axios.post('uni/qbExams/createManually', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createExamCourseSemester({ commit }, params) {
      try {
        const response = await axios.post('uni/qbExamCourseSemesters', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCourseSemesterSubject({ commit }, params) {
      try {
        const response = await axios.get('uni/examinationRooms/courseSemesterSubject', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_COURSE_SEMESTER_SUBJECT', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCourseSemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/examinationRooms/course_semesters', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES_SEMESTERS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateManually({ commit }, params) {
      try {
        const response = await axios.put(`uni/qbExams/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataSourcesByStatus({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams/getByStatus', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES_BY_STATUS', records)
          commit('SET_TOTAL_ROWS_BY_STATUS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataSourcesSelected({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams/getExamSelected', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES_SELECTED', records)
          commit('SET_TOTAL_ROWS_SELECTED', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getQbExamProclaimed({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams/getExamProclaimed', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES_PROCLAIMED', records)
          commit('SET_TOTAL_ROWS_PROCLAIMED', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getExamQuestionDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams/getQbExamQuestions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES_QUESTIONS', records)
          commit('SET_TOTAL_ROWS_QUESTION', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getExchangeExamQuestionDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams/getExchangeQbExamQuestions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES_EXCHANGE_QUESTIONS', records)
          commit('SET_TOTAL_ROWS_EXCHANGE_QUESTION', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post('uni/qbExams', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`uni/qbExams/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteExamCourseSemester({ commit }, id) {
      try {
        const response = await axios.delete(`uni/qbExamCourseSemesters/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exchangeExamQuestion({ commit }, params) {
      try {
        const response = await axios.put('uni/qbExams', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async importQuestions({ commit }, params) {
      try {
        const response = await axios.put('uni/qbExams/importExamQuestions', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdf({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams/exportPdfExam', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportExamTeacher({ commit }, params) {
      try {
        const response = await axios.get('uni/qbExams/exportPdfExamTeacher', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getProclaimExam({ commit }, params) {
      try {
        const response = await axios.put(`uni/qbExamCourseSemesters/proclaimExam/${params.qbExamCourseSemesterId}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
